/* eslint-disable */
import React, { useState, useCallback, useEffect } from "react"
// import Helmet from "react-helmet"
// import loadable from "@loadable/component"
// import { Router, Match } from "@reach/router"

import IndexSPA from "../components/IndexSPA"

const Index = props => {
  return (
    <>
      {/* <Helmet
        title="Page"
        meta={[
          {
            name: `description`,
            content: "Description"
          },
          {
            property: `og:title`,
            content: "Page"
          },
          {
            property: `og:description`,
            content: "Description"
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:image`
            // content: 'https://online.home-to-all.com' + require('../images/422socialimage.jpg')
          }
        ]}
      ></Helmet> */}
      <IndexSPA />
    </>
  )
}

// Index.propTypes = {}

export default Index
